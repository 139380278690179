import React from 'react';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import CarIcon from '@material-ui/icons/DriveEtaRounded';
//import GenieIcon from "../../MairoGenie.svg";
import P5Sketch from "../ui/p5Sketch"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 440,
    },
  })
  );

const StartingPageContent = () => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Container className={classes.container} >
      <h1><CarIcon /></h1>
      <Card className={fixedHeightPaper} >
      <P5Sketch>

        </P5Sketch>
</Card>
</Container>
  );
};

export default StartingPageContent;
