import React from "react";
import { useState, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import {
  injected,
  //network,
} from "../userAccounts/connectors";

import {
  FB_AuthLoginResponse,
  AuthTokenState,
  LoginProviders,
} from "./authModels";

import Loading from "../ui/loadingIndicator";
//Import AuthSlice
import { LoadFirebaseLoginDataAsync } from "./authFireBaseAPI";
import {
  authActions,
  selectAuth,
} from "./authSlice";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `3rem auto`,
      width: `95%`,
      maxQidth: `25rem`,
      borderRadius: `6px`,
      // backgroundColor: theme.palette.secondary.main,
      boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
      padding: `1rem`,
      textAlign: `left`,
    },
    control: {
      marginBottom: `0.5rem`,
    },
    label: {
      display: `block`,
      color: theme.palette.text.secondary,
      fontWeight: `bold`,
      marginBottom: "0.5rem",
    },
    input: {
      borderRadius: `4px`,
      width: `100%`,
      textAlign: `left`,
      padding: theme.spacing(2),
    },
    actions: {
      marginTop: `1.5rem`,
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
    },
    toggle: {
      marginTop: `1rem`,
      backgroundColor: `transparent`,
      color: theme.palette.primary.contrastText,
      border: `none`,
      padding: theme.spacing(1),
    },
  })
);
const AuthForm: React.FC = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const web3React = useWeb3React();
  const { connector, chainId, account, activate, deactivate, active, error } =
    web3React;

  const userEmailRef = useRef<HTMLInputElement>(null);
  const userSecRef = useRef<HTMLInputElement>(null);
  let currentLoginProvider: string = "SkyLynx"; 
  const apiSignInMethodName = "signInWithPassword";
  const apiSignUPMethodName = "signUp";
  const [activatingConnector, setActivatingConnector] = React.useState<any>();
  //Handles changes to the HTTP Hook
  useEffect(() => {
    if (!authState.isAuthLoading && authState.isLoggedIn) {
      //Convert expiredIn to number before passing to Context Handler
      //Get User Info State for Auth Slice
      // console.log(`Welcome back ${data.email} your id is ${data.localId}`);
      //return user to Home
      history.replace("/");
      //}// else if (!isAuthLoading && !error && reqIdentifer === apiSignUPMethodName) {
      // console.log(`Glad you joined with ${data.email}  `);
      //return user to Home
      // history.replace('/');
    } else if (!authState.isAuthLoading) {
      //TODO add error module for now just use alert
      //alert(error);
    }

    //Login with MetaMask if already connected

  }, [history, authState, account]);

  const metaMaskConnectHandler = (event: React.FormEvent) => {
      setActivatingConnector(injected);
      activate(injected);
    if (account) {
      dispatch(
        authActions.replaceLoginData({
          token: account,
          isLoggedIn: true,
          remainingTime: 5000,
          isAuthLoading: false,
          provider: "MetaMask",
        })
      );
    }
  }

  const logInSubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    let enteredEmail = "";
    let enteredPassword = "";

    //Validation of user input
    if (
      userEmailRef.current &&
      userEmailRef.current.value &&
      userSecRef.current &&
      userSecRef.current.value
    ) {
      enteredEmail = userEmailRef.current.value;
      enteredPassword = userSecRef.current.value;
    }

    //Add Login or Sign up
    if (!authState.isLoggedIn) {
      //Here we are logging a user in to the website
      //console.log(`Welcome back your emailaddress is still ${enteredEmail}`);
      //Dispatch LogIn here sendLoginRequest
      dispatch(
        LoadFirebaseLoginDataAsync({
          email: enteredEmail,
          secret: enteredPassword,
        })
      );
      //Examples of dispatching
      // dispatch(
      //   logInFirebaseAsync({
      //     email: enteredEmail,
      //     secret: enteredPassword,
      //   })
      // );
      //remove just for test
      //  const currentAuthState: AuthTokenState = {
      //    token: authState.token,
      //    isLoggedIn: true,
      //    remainingTime: authState.remainingTime,
      //    isAuthLoading: authState.isAuthLoading,
      //     hasError: authState.hasError,
      //   };
      //   dispatch(authActions.setLoginData(currentAuthState));
    } else {
      //Here we are signing a user up to use the site
      //Dispatch Sign Up here
      console.log(`Using SkyLynxs Network`);
    }
  };

  const switchAuthModeHandler = () => {
    // setuseMetaMask((prevState) => !prevState);
    if (currentLoginProvider === "SkyLynx") {
      currentLoginProvider = "Rabbit";
    } else {
      currentLoginProvider = "SkyLynx";
    }

  };

  return (
    <Card className={classes.root}>
      <CardHeader title={account ? "Login" : "Skylynx Connect"}></CardHeader>
      <form
        onSubmit={
          currentLoginProvider === "SkyLynx"
            ? logInSubmitHandler
            : metaMaskConnectHandler
        }
      >
        {currentLoginProvider === "SkyLynx" && (
          <CardContent className={classes.control}>
            <label className={classes.label} htmlFor="email">
              Your Email
            </label>
            <input
              className={classes.input}
              ref={userEmailRef}
              type="email"
              id="email"
              required
            />
          </CardContent>
        )}
        {currentLoginProvider === "SkyLynx" && (
          <CardContent className={classes.control}>
            <label className={classes.label} htmlFor="password">
              Your Password
            </label>
            <input
              className={classes.input}
              ref={userSecRef}
              type="password"
              id="password"
              required
            />
          </CardContent>
        )}
        <CardActions className={classes.actions}>
          {!authState.isAuthLoading && (
            <Button type="submit" variant="contained" color="inherit">
              {!account ? "Connect Skylynx" : "Login"}
            </Button>
          )}
          {authState.isAuthLoading && <Loading></Loading>}
          <Button
            type="button"
            className={classes.toggle}
            onClick={switchAuthModeHandler}
          >
            {currentLoginProvider === "Stellar"
              ? "Login with Rabbit"
              : "Connect Stellar Wallet"}
          </Button>
          {authState.isAuthLoading && <Loading></Loading>}
          <Button
            type="button"
            className={classes.toggle}
            onClick={switchAuthModeHandler}
          >
            {currentLoginProvider === "MetaMask"
              ? "Login with existing account"
              : "Connect Metamask Wallet"}
          </Button>
          {authState.isAuthLoading && <Loading></Loading>}
          <Button
            type="button"
            className={classes.toggle}
            onClick={switchAuthModeHandler}
          >
            {currentLoginProvider === "SkyLynxs SignUp"
              ? "Create a SkyLynxs Network Account"
              : "Create Account"}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AuthForm;
