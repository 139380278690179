import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import { PAGE_WIDTH } from "../../helpers/constants";
import { useWeb3React } from "@web3-react/core";
import {
  injected,
  //network,
} from "../userAccounts/connectors";
import WalletBalance from "./wallets/ethWalletBalance";
import AccountName from "./wallets/ethAccountName"
import BlockNumber from "./wallets/ethBlockNumber";

 const address = "Address";
 let currentETHAccount="Connet your MetaMask to see your account address here";
 //const Network = "Network";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(4),
      width: PAGE_WIDTH,
      flexDirection: "column",
      maxQidth: `25rem`,
      borderRadius: `6px`,
      // backgroundColor: theme.palette.secondary.main,
      boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      textAlign: `left`,
    },
    control: {
      marginBottom: `0.5rem`,
    },
    label: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    input: {
      flex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    action: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    content: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);

const ETHWalletCard = () => {
  const classes = useStyles();
  const history = useHistory();
  const web3React = useWeb3React();
  let Title = "Metamask Account:";
//Now get 

const {
  connector,
  chainId,
  account,
  activate,
  deactivate,
  active,
  error,
  library,
} = web3React;

// handle logic to recognize the connector currently being activated
const [activatingConnector, setActivatingConnector] = React.useState<any>();
React.useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      }
  if (error) {
    //Dispatch Show error
  }
}, [activatingConnector, connector, error, chainId, account]);
 // if (account) {
 //   Title = account ? `${Title} ${account.toString()}` : currentETHAccount;
 // }
  //this method turns account access on and off for the user
  const toggelMetaMaskAccountHandler = () => {
    setActivatingConnector(injected);
    activate(injected);
    //Dispatch Auth Login Request
    //currentETHAccount = account ? account.toString() : "Error Connecting";
  };
  return (
    <Card className={classes.root}>
    <CardHeader title={Title} subheader={account} >
    
      </CardHeader>
      <CardContent className={classes.content}>
          <WalletBalance></WalletBalance>

    
      </CardContent>
    </Card>
  );
};

export default ETHWalletCard;
