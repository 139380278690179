import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ETHWalletCard from "../../components/userAccounts/ETHWalletCard";
// components
import PageTitle from "../../components/ui/pageTitle";

// constants
import { APP_TITLE,  SUBPAGE_TITLE_ETH } from "../../helpers/constants";

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);

const WalletPage: FC<{}> = (): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          {SUBPAGE_TITLE_ETH} | {APP_TITLE}
        </title>
      </Helmet>
      <div className={classes.root}>
        <PageTitle title={SUBPAGE_TITLE_ETH} />
        <ETHWalletCard />
      </div>
    </>
  );
};

export default WalletPage;
