// icons
import React,{ useContext} from 'react';
import HomeIcon from '@material-ui/icons/Home';
import LoginIcon from '@material-ui/icons/AddToHomeScreenOutlined';
import DashboardIcon from '@material-ui/icons/BarChartOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BankIcon from '@material-ui/icons/AccountTreeOutlined';
import EthIcon from '@material-ui/icons/AccountBoxOutlined';
import CompoundIcon from "@material-ui/icons/MonetizationOnOutlined";
import InvestIcon from "@material-ui/icons/Money";
import UserIcon from "@material-ui/icons/VerifiedUserRounded";

//Authorization
import { useAppSelector } from '../hooks/reduxHooks';

// components
import Home from '../pages/home';
import Dashboard from '../pages/dashboard';

import BankPage from '../pages/accounts/bankAccount';
import WalletPage from '../pages/accounts/walletsAccounts';
import Settings from '../pages/settings';
import AuthPage from '../pages/authPage';
import CompoundPage from "../pages/stellar";
import InvestPage from "../pages/invest";


// interface
import RouteItem from './RouteItem.model';
import {
    selectAuth,
  } from '../components/auth/authSlice';
// define app routes
export const Loadroutes =() => {
    const authState = useAppSelector(selectAuth);
    const isLoggedIn = authState.isLoggedIn;
    let routes: Array<RouteItem> = [];
if(isLoggedIn !== undefined)
{
routes = [
  {
    key: "router-home",
    title: "Home",
    tooltip: "Home",
    path: "/",
    enabled: true,
    component: Home,
    icon: WalletIcon,
    appendDivider: true,
  },
  {
    key: "router-dashboard",
    title: "Markets",
    tooltip: "Market Dashboard",
    path: "/markets",
    enabled: isLoggedIn,
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    key: "router-compound",
    title: "Stellar",
    tooltip: "Manage Account",
    path: "/compound",
    enabled: isLoggedIn,
    component: CompoundPage,
    icon: CompoundIcon,
  },
  {
    key: "router-invest",
    title: "Invest",
    tooltip: "DecentralLand",
    path: "/invest",
    enabled: isLoggedIn,
    component: InvestPage,
    icon: InvestIcon,
  },

  {
    key: "router-act",
    title: "Accounts",
    tooltip: "Accounts",
    enabled: isLoggedIn,
    icon: WalletIcon,
    subRoutes: [
      {
        key: "router-account-bank",
        title: "Bank Accounts",
        tooltip: "Bank Accounts",
        path: "/accounts/bank",
        enabled: isLoggedIn,
        component: BankPage,
        icon: BankIcon,
      },
      {
        key: "router-account-eth",
        title: "Wallets",
        tooltip: "Connect Crypto Wallets Here",
        path: "/accounts/wallets",
        enabled: isLoggedIn,
        component: WalletPage,
        icon: EthIcon,
      },
    ],
  },
  {
    key: "router-auth",
    title: "User",
    tooltip: "User Identity",
    path: "/auth",
    enabled: !isLoggedIn,
    component: AuthPage,
    icon: UserIcon,
  },
  {
    key: "router-settings",
    title: "Settings",
    tooltip: "Settings",
    path: "/settings",
    enabled: isLoggedIn,
    component: Settings,
    icon: SettingsIcon,
  },
];
}
return routes;


} ;