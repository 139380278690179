import React from "react";
import PropTypes from "prop-types";
import {createMuiTheme,withStyles,Theme,ThemeOptions} from "@material-ui/core/styles";
import {blue,lightBlue,lightGreen,teal,pink,yellow,deepPurple} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import cryoRIOLightPrimaryColor  from "./cryoRIOLightPrimary";




const styles = {
    root: {
    display: "flex",
    },
};

//Would like to use this in both themes figure out how later

// define light theme colors
export const lightTheme: Theme = createMuiTheme({
  palette: {
    type: "light",
    //dark blue
    primary: {
      //main: blue[900],
      main: "#1D5E72",
    },
    //light blue
    secondary: {
      //main: lightBlue[500],
      main: "#94942F",
    },
    info: {
      //main: teal[400],
      main: "#FFFFD6",
    },
    warning: {
      // main: yellow[900],
      main: pink[400],
    },
  },
  typography: {
    fontFamily: "Showcard Gothic",
    fontSize: 18,
    fontWeightMedium: 500,
    body1: {
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    body2: {
      fontFamily: "Roboto Mono",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 35,
    },
    button: {
      fontStyle: "italic",
      fontFamily: "Roboto Mono",
    },
  },
});

// define dark theme colors
export const darkTheme: Theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: deepPurple[500],
    },
    secondary: {
      main: pink[400],
    },
    info: {
      main: pink[700],
    },
    warning: {
      main: yellow[400],
    },
  },
  typography: {
    fontFamily: "Showcard Gothic",
    fontSize: 18,
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 35,
    },
    button: {
      fontStyle: "italic",
    },
  },
});
