
import {createAsyncThunk,} from "@reduxjs/toolkit";
import {FB_AuthLoginResponse,AuthFireBaseLogInReq} from "./authModels";
import { FIREBASE_APIKEY } from "../../helpers/constants";
import { uiActions } from "../../components/ui/uiSlice";

  //values for Firebase API Post commands
const apiSignInCommandNameName = "signInWithPassword";
const apiSignUPCommandName = "signUp";


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const LoadFirebaseLoginDataAsync = createAsyncThunk(
"api/firebase/login",
async (loginParams: AuthFireBaseLogInReq, thunkAPI) => {
    //set initstate for respn
    let response = {
    data: {
        idToken: "",
        email: "",
        refreshToken: "",
        expiresIn: "0",
        localId: "",
        registered: false,
    },
    };

    thunkAPI.dispatch(
    uiActions.showToast({
        status: "loading",
        message: "Trying to log you in.",
    })
    );

    // The value we return becomes the `fulfilled` action payload
    //So here we need to do other Login Task
    const sendRequest = async () => {
      let fbresponse = await fetch(
        `https://identitytoolkit.googleapis.com/v1/accounts:${apiSignInCommandNameName}?key=${FIREBASE_APIKEY}`,
        {
          method: "POST",
          body: JSON.stringify({
            email: loginParams.email,
            password: loginParams.secret,
            returnSecureToken: true,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!fbresponse.ok) {
        return {
          idToken: "",
          email: "",
          refreshToken: "",
          expiresIn: "0",
          localId: "",
          registered: false,
        };
      } else {
        return fbresponse.json();
      }
    };

    try {
      let data: FB_AuthLoginResponse = await sendRequest();
      //convert to type
      if (data.registered) {
        thunkAPI.dispatch(uiActions.updateToastMessage("Great You made it!"));
        thunkAPI.dispatch(uiActions.hideToast());
        //Dispach Login
        response = { data: data };

        // setTimeout(authSlice.actions.logout, 3000);
      } else {
        //
        thunkAPI.dispatch(uiActions.updateToastMessage(""));
        thunkAPI.dispatch(
          uiActions.showToast({
            status: "error",
            message: "Try again Buster!.",
          })
        );
      }
    } catch {
      //Disptach error message
    }
    return response;
  }
);
