import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import BalanceIcon from "@material-ui/icons/AccountBalanceOutlined";
import CardContent from "@material-ui/core/CardContent";
import {
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { formatEther } from "@ethersproject/units";


 const  LoadBalance = async () =>{

 }
 const WalletBalance: FC<{}> = (): ReactElement => {
   const { account, library, chainId } = useWeb3React();

   const [balance, setBalance] = React.useState<any>();
   React.useEffect((): any => {
     if (!!account && !!library) {
       let stale = false;
       const getWalletBalance = () => {
         library
           .getBalance(account)
           .then((balance: any) => {
             if (!stale) {
               setBalance(balance);
             }
           })
           .catch(() => {
             if (!stale) {
               setBalance(null);
             }
           });
       };
       getWalletBalance();
       return () => {
         stale = true;
         setBalance(undefined);
       };
     }
   }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

   return (
     <>
       <CardContent><BalanceIcon/>
         {balance === null
           ? "Error"
           : balance
           ? `${formatEther(balance)}`
           : ""}
       </CardContent>
     </>
   );
 };



export default WalletBalance;