import React, { FC, ReactElement,ReactNode,Component,createRef,useRef ,useState, memo } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import P5 from "p5";
import { Link } from "@material-ui/core";
//Get api for sketch
import {createCanvas,P5Instance,Sketch} from "./reactP5WrapperComponent"
import {Tree} from "../p5sketches/trees/treeSketch";
import {ButterFly} from "../p5sketches/butterfly/butterFly";
import {SketchLive} from "../p5sketches/trees/sketchLive";


// constants
import { Sketch_HEIGHT, Sketch_WIDTH } from "../../helpers/constants";
import p5 from "p5";



// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      background: theme.palette.background.paper,
      minHeight: Sketch_HEIGHT,
    },
    footer: {
      textTransform: "uppercase",
    },
  })
);


// define interface to represent component props for sketch
// loadSketch: is the method that loads the sketch 
interface Props {
  loadSketch: (p:P5) => void;
  drawbranch: () => void;
  children: ReactNode;
}


// functional component
const P5Sketch: FC<{}> = memo((): ReactElement => {
  const classes = useStyles();
  const [isFirstLoaded, setFirstLoad] = React.useState<boolean>(true);
  //This will load when Sketch changes
  const [currentSketch, setSketch] = React.useState<any>();
  const wrapper = createRef<HTMLDivElement>();



  const [instance, setInstance] = useState<P5Instance>();
  React.useEffect((): any => {
    //the sketch we want to add but we will pass this with props
    if (wrapper.current === null || SketchLive === undefined ) return;
    if(isFirstLoaded)
      {
    instance?.remove();
    const canvas = createCanvas(SketchLive, wrapper.current);
    setFirstLoad(false);
    setInstance(canvas);
    }

    //const t = new myp5()
  }, [instance,wrapper,isFirstLoaded]);




//className={classes.root}
  return (
    <div  ref={wrapper}>
          </div>
  );
});

export default P5Sketch;
