import React, { ReactElement, FC } from "react";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import UserIcon from "@material-ui/icons/AccountCircle";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import LogoutIN from "@material-ui/icons/MeetingRoom";
import SvgIcon from "@material-ui/core/SvgIcon";
// constants
import { APP_TITLE, DRAWER_WIDTH } from "../../helpers/constants";
//Use Redux hooks to get to see user is logged in and get actions for disptach
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';

//Import AuthSlice
import {
  selectAuth,
} from '../auth/authSlice';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    imageIcon: {
      height: "100%",
    },
    iconRoot: {
      textAlign: "center",
    },
  })
);

// define interface to represent component props
interface Props {
  open: boolean;
  handleMenuOpen: () => void;
  handleMetaMaskConnect: () => void;
  handleRabetConnect: () => void;
  handleDisconnect: () => void;
  toggleTheme: () => void;
  useDefaultTheme: boolean;
}


const Header: FC<Props> = ({
  open,
  handleMenuOpen,
  handleMetaMaskConnect,
  handleRabetConnect,
  handleDisconnect,
  toggleTheme,
  useDefaultTheme,
}): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const isLoggedIn = authState.isLoggedIn;
  let error:boolean = false;


  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <IconButton
              color="inherit"
              aria-label="open menu"
              onClick={handleMenuOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              size="small"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {APP_TITLE}
            </Typography>
          </div>
          <IconButton onClick={toggleTheme}>
            {useDefaultTheme ? (
              <Tooltip title="Switch to dark mode" placement="bottom">
                <Brightness3Icon />
              </Tooltip>
            ) : (
              <Tooltip title="Switch to light mode" placement="bottom">
                <Brightness7Icon />
              </Tooltip>
            )}
          </IconButton>
          <IconButton size="small" color="inherit">
            <UserIcon />
          </IconButton>
          {!isLoggedIn && (
            <IconButton
              size="small"
              onClick={handleMetaMaskConnect}
              color="inherit"
            >
              <Icon classes={{ root: classes.iconRoot }}>
                <img
                  className={classes.imageIcon}
                  src="/logos/metamask-logo-vector.svg"
                />
              </Icon>
            </IconButton>
          )}
          {!isLoggedIn && (
            <IconButton
              size="small"
              onClick={handleRabetConnect}
              color="inherit"
            >
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} src="/logos/rabet128.svg" />
              </Icon>
            </IconButton>
          )}
          {isLoggedIn && (
            <IconButton size="small" onClick={handleDisconnect} color="inherit">
              <LogoutIcon />
            </IconButton>
          )}
          <h1 style={{ margin: "1rem", textAlign: "right" }}>
            {isLoggedIn ? "🟢" : error ? "🔴" : "🟠"}
          </h1>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
