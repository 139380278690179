import React, { FC, ReactNode, useReducer } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// components
import Header from "./header";
import Navigation from "./navigation";
import Footer from "./footer";
//Move out to button
import {useWeb3React } from "@web3-react/core";
//Models
import { MetaMask_ConnectResponse } from "../../components/auth/authModels";
import {
  injected,
  //network,
} from "../userAccounts/connectors";
import {
  authActions,
  selectAuth,
} from "../../components/auth/authSlice";
// constants
import { DRAWER_WIDTH, FOOTER_HEIGHT } from "../../helpers/constants";
import { useAppDispatch } from "../../hooks/reduxHooks";
// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
      background: theme.palette.background.paper,
      marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: DRAWER_WIDTH,
    },
  })
);

// define interface to represent component props
interface Props {

  toggleTheme: () => void;
  useDefaultTheme: boolean;
  children: ReactNode;
}

// functional component
const Layout: FC<Props> = ({
  toggleTheme,
  useDefaultTheme,
  children,
}) => {
  const classes = useStyles();
  const [open, toggle] = useReducer((open) => !open, true);
  const history = useHistory();
  //setup web3 Hooks
  const web3React = useWeb3React();
  const dispatch = useAppDispatch();
  //Setup Web3 Connect button to Log users into system
  const {
  connector,
  chainId,
  account,
  activate,
  deactivate,
  active,
  error,
  library,
} = web3React;
//useEffect for web3 Ethers state set up
const [activatingConnector, setActivatingConnector] = React.useState<any>();
React.useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      }
  if (error) {
    //Dispatch Show error
  }
}, [activatingConnector, connector, error, chainId, account]);

  //add a slice of web3 Connect and disconnect
  const connectMetaMaskHandler =  async () => {

    try {
    setActivatingConnector(injected);
    await activate(injected);

  const args:MetaMask_ConnectResponse = { addressKey:"***fukyou***"};
  dispatch(authActions.setMetaMaskLoginData(args));
    //history.replace("/");
    } catch (ex) {
      console.log(ex);
    }

  };

    const connectRabetHandler = async () => {
      try {
        setActivatingConnector(injected);
        await activate(injected);

        const args: MetaMask_ConnectResponse = { addressKey: "***fukyou***" };
        dispatch(authActions.setMetaMaskLoginData(args));
        //history.replace("/");
      } catch (ex) {
        console.log(ex);
      }
    };

  const logoutHandler = () => {
    dispatch(authActions.logout());
    deactivate();
    history.replace("/");
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        open={open}
        handleMenuOpen={toggle}
        handleMetaMaskConnect={connectMetaMaskHandler}
        handleRabetConnect={connectRabetHandler}
        handleDisconnect={logoutHandler}
        toggleTheme={toggleTheme}
        useDefaultTheme={useDefaultTheme}
      />
      <Navigation open={open} handleMenuClose={toggle} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.toolbar} />
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
